import { FETCHING_STATUS } from '@/constants';
import { IResponse } from '@/types/global';
import {
  IBrandVoiceConfig,
  IBrandVoiceConfigResponse,
  IBrandVoiceReceipt,
  IBrandVoiceResponse,
} from '@/types/settings';
import { axiosGet, axiosPost } from '@/utils/axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setGeneratingVoiceStatus = createAction<FETCHING_STATUS>('brand/setGeneratingVoiceStatus');

export const saveBrandVoiceConfig = createAsyncThunk(
  'brand/saveBrandVoiceConfig',
  async ({ configs }: { configs: IBrandVoiceConfig }): Promise<IResponse<IBrandVoiceConfigResponse>> => {
    try {
      const { data } = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}v2/brandvoice/settings`, {
        configs,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const getBrandVoiceConfig = createAsyncThunk(
  'brand/getBrandVoiceConfig',
  async (): Promise<IResponse<IBrandVoiceConfigResponse>> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}v2/brandvoice/settings`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const generateBrandVoice = createAsyncThunk(
  'brand/generateBrandVoice',
  async (): Promise<IResponse<IBrandVoiceReceipt>> => {
    try {
      const { data } = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}v2/brandvoice/insighttask`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const saveBrandVoice = createAsyncThunk(
  'brand/saveBrandVoice',
  async ({ brandVoice }: { brandVoice: string }): Promise<IResponse<IBrandVoiceResponse>> => {
    try {
      const { data } = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}/v2/brandvoice`, { brandVoice });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
export const getBrandVoiceByReceipt = createAsyncThunk(
  'brand/getBrandVoiceByReceipt',
  async ({ receipt }: { receipt: string }): Promise<IResponse<IBrandVoiceResponse>> => {
    try {
      const { data } = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}v2/brandvoice/insighttask?receipt_number=${receipt}`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:22 ~ e:', e);
      throw e;
    }
  },
);
