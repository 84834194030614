import React, { FC, useState, useEffect, useMemo } from 'react';
import { Divider, Button, Input, Select, Dropdown, Space, Menu } from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './index.less';
import { useNavigate, useParams } from 'react-router-dom';
import { useTicket } from '@/features/plugin/hooks';
import { useOrg } from '@/features/settings/hooks';
import moment from 'moment';
import TicketGlobalEffect from '../globalEffect';
import { DATE_FORMAT_DETAIL } from '@/constants';
import DeleteTicket from './../DeleteTicket';
import { useTeamMembers } from '@/features/team/hooks';
import { useAppDispatch } from '@/features/hooks';
import { updateTicket, delTicketAttachment } from '@/features/plugin/actions';
import { TICKET_PRIORITY, TICKET_STATUS } from '@/constants/Tickets';
import CommentList from './CommentList';
import CommentInput from './CommentInput';
import CorUploader from './CorUploader';
import { useComments } from '@/features/comment/hooks';
import CommentGlobalEffect from '@/features/comment/globalEffect';
import { IComment } from '@/types/comment';
const { Option } = Select;
import { ReactComponent as ArrowBackIcon } from '@/assets/svg/icon_arrow_back.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icon_edit.svg';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

const TicketDetail: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const org = useOrg();
  const members = useTeamMembers(org?.id);
  const membersFiltered = useMemo(
    () => members,
    // ?.filter((m) => m.status !== MEMBER_STATUS.PENDING)
    [members],
  );

  const { id } = useParams();
  const ticket = useTicket(org?.id, id);
  const comments = useComments(id);
  const [isEdit, setIsEdit] = useState(true);
  const [editingComment, setEditingComment] = useState<IComment | null>(null); // 管理当前编辑的评论
  const [fieldValues, setFieldValues] = useState({
    title: ticket?.title || '',
    customerEmail: ticket?.customerEmail || '',
    merchantEmail: ticket?.merchantEmail || '',
    content: ticket?.content || '',
    assignees: ticket?.assignees || [],
    priority: ticket?.priority || '',
    status: ticket?.status || '',
  });
  useEffect(() => {
    if (ticket?.id) {
      setFieldValues({
        title: ticket.title,
        customerEmail: ticket.customerEmail,
        merchantEmail: ticket.merchantEmail,
        content: ticket.content,
        assignees: ticket.assignees,
        priority: ticket.priority,
        status: ticket.status,
      });
    }
  }, [ticket]);

  const handleInputChange = (field: string, value: any) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSave = async (type: string) => {
    setIsEdit(false);
    // 这里可以添加保存逻辑，更新后端数据
    if (!ticket || !org) return;
    await dispatch(
      updateTicket({
        ...ticket,
        ...fieldValues,
        orgId: org?.id,
        assignees: fieldValues.assignees.join(','),
      }),
    ).unwrap();
  };
  const handleCancel = async () => {
    ticket &&
      setFieldValues({
        title: ticket.title,
        customerEmail: ticket.customerEmail,
        merchantEmail: ticket.merchantEmail,
        content: ticket.content,
        assignees: ticket.assignees,
        priority: ticket.priority,
        status: ticket.status,
      });
    setIsEdit(false);
  };
  const onDelete = async (name: string) => {
    await dispatch(
      delTicketAttachment({
        ticketId: id || '',
        attachName: name,
      }),
    ).unwrap();
  };
  const handleOpen = async (responseURL: string) => {
    window.open(`${process.env.REACT_APP_API_ROOT}s3/file?url=${responseURL} `);
  };
  const handleEditComment = (comment: IComment) => {
    setEditingComment(comment); // 设置当前编辑的评论
  };

  const handleCommentInputClose = () => {
    setEditingComment(null); // 清除编辑状态
  };

  const statusMenu = useMemo(() => {
    const opt = [];
    for (const key in TICKET_STATUS) {
      if (TICKET_STATUS.hasOwnProperty(key) && key !== 'ALL') {
        opt.push({
          label: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
          key: TICKET_STATUS[key as keyof typeof TICKET_STATUS],
        });
      }
    }
    return (
      <Menu
        className="cor-ticket-detail-dropdown-menu"
        items={opt}
        onClick={(e) => handleInputChange('status', e.key)}
      />
    );
  }, []);

  const priorityMenu = useMemo(() => {
    const opt = [];
    for (const key in TICKET_PRIORITY) {
      if (TICKET_PRIORITY.hasOwnProperty(key) && key !== 'ALL') {
        opt.push({
          label: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
          key: TICKET_PRIORITY[key as keyof typeof TICKET_PRIORITY],
        });
      }
    }
    return (
      <Menu
        className="cor-ticket-detail-dropdown-menu"
        items={opt}
        onClick={(e) => handleInputChange('priority', e.key)}
      />
    );
  }, []);

  return (
    <div className="cor-ticket-detail" style={{ padding: '20px 5px' }}>
      <div className="cor-ticket-detail-header">
        <Button
          type="text"
          shape="circle"
          icon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          className="back-button"
        />
        <span style={{ marginLeft: '10px' }}>Back to Tickets</span>
      </div>
      <div className="cor-ticket-detail-body">
        <div className="cor-ticket-detail-card">
          <div className="cor-ticket-detail-title">TICKET DETAILS</div>
          <div className="cor-ticket-detail-name">
            {isEdit ? (
              <>
                <p>Ticket title</p>
                <Input
                  value={fieldValues.title}
                  onChange={(e) => handleInputChange('title', e.target.value)}
                  style={{ flex: 1 }}
                />
              </>
            ) : (
              <span>{fieldValues.title}</span>
            )}
          </div>
          <div className="cor-ticket-detail-description">
            {isEdit ? (
              <>
                <p>Description</p>
                <Input.TextArea
                  value={fieldValues.content}
                  onChange={(e) => handleInputChange('content', e.target.value)}
                  style={{ flex: 1 }}
                  rows={3}
                />
              </>
            ) : (
              <span>{fieldValues.content}</span>
            )}
          </div>
          <Divider
            style={{
              background: 'rgba(31, 44, 71, 0.07)',
            }}
          />
          <div className={classNames('cor-ticket-detail-emails', isEdit && 'isEdit')}>
            <div className="cor-ticket-detail-email">
              <p>Customer Email</p>
              {isEdit ? (
                <Input
                  value={fieldValues.customerEmail}
                  onChange={(e) => handleInputChange('customerEmail', e.target.value)}
                  style={{ flex: 1 }}
                />
              ) : (
                <span>{fieldValues.customerEmail}</span>
              )}
            </div>
            <div className="cor-ticket-detail-email">
              <p>Merchant Email</p>
              {isEdit ? (
                <Input
                  value={fieldValues.merchantEmail}
                  onChange={(e) => handleInputChange('merchantEmail', e.target.value)}
                  style={{ flex: 1 }}
                />
              ) : (
                <span>{ticket?.merchantEmail}</span>
              )}
            </div>
          </div>

          <Divider
            style={{
              background: 'rgba(31, 44, 71, 0.07)',
            }}
          />
          <div className={classNames('cor-ticket-detail-assignees', isEdit && 'isEdit')}>
            <p>Assignee Email</p>
            {isEdit ? (
              <Select
                mode="multiple"
                value={fieldValues.assignees}
                onChange={(value) => handleInputChange('assignees', value)}
                style={{ flex: 1 }}>
                {membersFiltered?.map((member) => (
                  <Option key={member.email} value={member.email}>
                    {member.email}
                  </Option>
                ))}
              </Select>
            ) : (
              <>
                {fieldValues.assignees.length ? (
                  <div className="assignees-box">
                    {fieldValues.assignees.map((assignee) => (
                      <span key={assignee}>{assignee}</span>
                    ))}
                  </div>
                ) : (
                  <span style={{ opacity: 0.5 }}>No assignees</span>
                )}
              </>
            )}
          </div>
          <div className="cor-ticket-detail-status">
            <p>Status</p>
            <Dropdown
              disabled={!isEdit}
              overlay={statusMenu}
              className={classNames('cor-ticket-detail-status-dropdown', `${fieldValues.status}`)}>
              <Space>
                {fieldValues.status.charAt(0).toUpperCase() + fieldValues.status.toLowerCase().slice(1)}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          <div className="cor-ticket-detail-priority">
            <p>Priority</p>
            <Dropdown
              disabled={!isEdit}
              overlay={priorityMenu}
              className={classNames('cor-ticket-detail-priority-dropdown', `${fieldValues.priority}`)}>
              <Space>
                {fieldValues.priority.charAt(0).toUpperCase() + fieldValues.priority.toLowerCase().slice(1)}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div>
          <Divider
            style={{
              background: 'rgba(31, 44, 71, 0.07)',
            }}
          />
          <div className="cor-ticket-detail-attachments">
            {/* <p>Attachments</p> */}
            <CorUploader id={id} text="Upload attachment"></CorUploader>
            {ticket?.attachments && ticket.attachments.length > 0 ? (
              <>
                <div className="attachment-list">
                  {ticket.attachments.map((item, index) => (
                    <div key={index} className="attachment-item">
                      <div onClick={() => handleOpen(item.attach)}>{item.name}</div>
                      {isEdit && (
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          onClick={() => onDelete(item.name)}
                          danger
                          className="attachment-delete">
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="attachment-no-more">No uploads attached.</div>
            )}
          </div>
          <div className="cor-ticket-detail-footer">
            <div>{id && <DeleteTicket id={id} />}</div>
            <div>
              {isEdit ? (
                <>
                  <Button type="text" onClick={handleCancel}>
                    Cancel Changes
                  </Button>
                  <Button type="primary" onClick={() => handleSave('title')}>
                    Save changes
                  </Button>
                </>
              ) : (
                <>
                  <div className="ticket-card-footer-time">
                    Last saved Sep {moment(ticket?.updatedAt).format(DATE_FORMAT_DETAIL)}
                  </div>
                  <Button type="primary" icon={<EditIcon />} onClick={() => setIsEdit(!isEdit)}>
                    Edit Ticket
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <Divider
          type="vertical"
          style={{
            height: 'initial',
            margin: '0 25px',
            background: 'rgba(31, 44, 71, 0.07)',
          }}
        />
        <div className="inner-card-right">
          <h3 className="inner-card-right-title">COMMENTS</h3>
          <div className="comments">
            <CommentList comments={comments || []} onEditComment={handleEditComment} />
          </div>
          <div className="comments-input">
            <CommentInput id={id || ''} initialComment={editingComment} onClose={handleCommentInputClose} />
          </div>
        </div>
      </div>
      <TicketGlobalEffect />
      <CommentGlobalEffect ticketId={id || ''} />
    </div>
  );
};

export default TicketDetail;
