import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { Button, Upload, Spin } from 'antd';
import { getUploadPresign } from '@/features/settings/actions';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import _ from 'lodash';
import { useAppDispatch } from '@/features/hooks';
import { addTicketAttachment } from '@/features/plugin/actions';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  text?: string;
}

const CorUploader: FC<IPropTypes> = function ({ id, text = 'Upload' }) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false); // State for loading indicator

  const getPresignedUrl = useCallback(async () => {
    const url = await dispatch(getUploadPresign()).unwrap();
    return url;
  }, [dispatch]);

  const addTicketAttachmentfile = useCallback(
    async (attachment: string, attachName: string) => {
      const url = await dispatch(
        addTicketAttachment({
          ticketId: id || '',
          attachment: attachment,
          attachName: attachName,
        }),
      ).unwrap();
      return url;
    },
    [dispatch, id],
  );

  return (
    <div className="cor-cor-uploader">
      <Upload
        showUploadList={false} // Hide file list
        customRequest={async ({ file, onError, onSuccess }) => {
          if (file && onError && onSuccess) {
            const fileType = _.get(file, ['type']);
            try {
              setLoading(true); // Show loading indicator
              const signedUrl = await getPresignedUrl();
              const options = {
                headers: {
                  'Content-Type': fileType,
                },
              };

              const uploadResult = await axios.put(signedUrl, file, options);

              if (uploadResult.status === 200) {
                addTicketAttachmentfile(uploadResult.request.responseURL, file?.name);
                onSuccess(uploadResult, file);
              }
            } catch (error) {
              console.error('上传失败:', error);
              onError(error);
            } finally {
              setLoading(false); // Hide loading indicator
            }
          }
        }}
        name="ticketUpload">
        <Button icon={<UploadOutlined />}>{text}</Button>
      </Upload>
      {loading && <Spin style={{ marginTop: 10 }} />} {/* Display loading spinner */}
    </div>
  );
};

export default CorUploader;
