import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './index.less';
import { useAppDispatch } from '@/features/hooks';
import { addComment, updateComment } from '@/features/comment/actions';
import { useAuth0 } from '@auth0/auth0-react';
import Avatar from 'react-avatar';
import InputUploader from './InputUploader';

interface Attachment {
  name: string;
  attach: string;
}

interface CommentInputProps {
  id: string;
  initialComment?: {
    id: string;
    content: string;
    attachments: Attachment[];
  } | null;
  onClose?: () => void;
}

const CommentInput: React.FC<CommentInputProps> = ({ id, initialComment, onClose }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const [comment, setComment] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState(false); // State for loading

  useEffect(() => {
    if (initialComment) {
      setComment(initialComment.content);
      if (initialComment.attachments && initialComment.attachments.length > 0) {
        setAttachments(initialComment.attachments);
      }
      setIsModalVisible(true);
    }
  }, [initialComment]);

  const handleUploadSuccess = (fileInfo: Attachment) => {
    setAttachments([...attachments, fileInfo]);
  };

  const handleDeleteAttachment = (index: number) => {
    const newAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };

  const handleSend = async () => {
    if (comment.trim() && user?.email) {
      setLoading(true);
      try {
        if (initialComment) {
          // 更新评论的逻辑
          await dispatch(
            updateComment({
              ticketId: id,
              id: initialComment.id,
              content: comment,
              attachments: attachments,
            }),
          ).unwrap();
        } else {
          // 新增评论的逻辑
          await dispatch(
            addComment({
              ticketId: id,
              author: user?.email,
              authorName: user?.name || '',
              content: comment,
              attachments: attachments,
            }),
          ).unwrap();
        }
        setComment('');
        setIsModalVisible(false);
        onClose?.();
      } catch (error) {
        console.error('Failed to send comment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    onClose?.();
  };

  const showModal = () => {
    setAttachments([]);
    setComment('');
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="cor-commnet" onClick={showModal}>
        <Avatar round size="46" name={user?.name} />
        <div className="cor-commnet-input">Add comment</div>
      </div>

      <Modal
        title="Add Comment"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="comment-modal">
        <Input.TextArea
          rows={3}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write a comment..."
          className="comment-input"
        />
        <InputUploader onUploadSuccess={handleUploadSuccess} />

        {attachments.length > 0 && (
          <List
            className="attachment-list"
            dataSource={attachments}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <div>{item.name}</div>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteAttachment(index)}
                  danger
                  className="attachment-delete">
                  Delete
                </Button>
              </List.Item>
            )}
          />
        )}

        <Button type="primary" onClick={handleSend} loading={loading} disabled={loading} className="send-button">
          Send
        </Button>
      </Modal>
    </>
  );
};

export default CommentInput;
