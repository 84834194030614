import React, { Suspense, useEffect } from 'react';
import { hot } from 'react-hot-loader';

import { Route, Navigate, Routes } from 'react-router-dom';
import AuthGlobalEffect from '@/features/auth/GlobalEffect';
import SettingsGlobalEffect from '@/features/settings/GlobalEffect';
import InboxGlobalEffect from '@/features/inbox/globalEffect';
import UserGlobalEffect from '@/features/user/globalEffect';
import BrandVoiceGlobalEffect from '@/features/brandvoice/globalEffect';
import ShopConnectionsGlobalEffect from '@/features/shopconnect/globalEffect';
import SocketGlobalEffect from '@/features/socket/globalEffect';
import Login from './pages/Login';
import LiveDemo from './pages/demo';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Dashboard/Profile';
import Users from './pages/Dashboard/Users';
import { Spin } from 'antd';
import { ToastContainer } from 'react-toastify';
import EmailGlobalEffect from '@/features/mail/globalEffect';
import 'react-toastify/dist/ReactToastify.css';
import './App.less';
import GmailCallBack from './pages/Dashboard/Settings/GmailCallBack';
import OutlookCallBack from './pages/Dashboard/Settings/OutlookCallBack';
import Loading from './pages/Loading';
const Inbox = React.lazy(() => import('@/pages/Inbox'));
const InboxMobile = React.lazy(() => import('@/pages/InboxMobile'));
const MetaMobile = React.lazy(() => import('@/pages/MetaMobile'));
const PluginMobile = React.lazy(() => import('@/pages/PluginMobile'));
const UserGuidanceMobile = React.lazy(() => import('@/pages/DashboardMobile/UserGuidance'));
const MailBoard = React.lazy(() => import('@/pages/MailBoard'));
const Settings = React.lazy(() => import('@/pages/Dashboard/Settings'));
const BrandVoice = React.lazy(() => import('@/pages/BrandVoice'));
const Plugin = React.lazy(() => import('@/pages/Plugin'));
const BrandVoiceMobile = React.lazy(() => import('@/pages/BrandVoiceMobile'));
import Landing from './pages/Landing';
import LandingMobile from './pages/LandingMobile';
import Privacy from './pages/Privacy';
import UserGuidance from './pages/Dashboard/UserGuidance';
import GoogleLimitedPage from './pages/LimitedUseDisclosure';
import { useMediaQueryDevice } from './components/MediaQuery';
import Analysis from './pages/Analysis';
import Team from './pages/Team';
import { useOrg } from './features/settings/hooks';
import useResponsiveFontSize from './hooks/useResponsiveFontSize';
import Tickets from './pages/Tickets';
import TicketDetail from './pages/Tickets/TicketDetail';
import Subscription from './pages/Subscription';
import LoginSuccess from './pages/LoginSuccess';
import WebpageDetail from './pages/Dashboard/Settings/KnowledgeBase/WebpageDetail';
import WOW from 'wowjs';
import 'animate.css';
import About from './pages/About';
import Career from './pages/Career';

import SettingsProfile from '@/pages/Dashboard/Settings/Profile';
import SettingsConnections from '@/pages/Dashboard/Settings/Connections';
import SettingsKnowledgeBase from '@/pages/Dashboard/Settings/KnowledgeBase';
import SettingsTemplate from '@/pages/Dashboard/Settings/Template';
import SettingsPlugin from '@/pages/Plugin';
import SettingsTeam from '@/pages/Team';

import InboxGmail from '@/pages/Inbox/InboxGmail';
import InboxMeta from '@/pages/Inbox/InboxMeta';
import PluginSessions from '@/pages/Plugin/PluginSessions';

function App() {
  useResponsiveFontSize();
  const org = useOrg();
  const { isMobile } = useMediaQueryDevice();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    // adapt mobile height
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
      doc.style.setProperty('--vh', `${window.innerHeight / 100}px`);
      doc.style.setProperty('--doc-width', `${window.innerWidth}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
  }, []);

  return (
    <div className={`App`}>
      <Suspense
        fallback={
          <div className="App-fallback">
            <Spin size="large" spinning={true} />
          </div>
        }>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="" element={<Loading />} />
            {isMobile ? (
              <Route path="home" element={<UserGuidanceMobile />} />
            ) : (
              <Route path="home" element={<UserGuidance />} />
            )}
            {isMobile ? (
              <Route path="inbox" element={<InboxMobile />} />
            ) : (
              <Route path="inbox" element={<Inbox />}>
                <Route index element={<InboxGmail />} />
                <Route path="meta" element={<InboxMeta />} />
                <Route path="store_plugin" element={<PluginSessions />} />
              </Route>
            )}
            <Route path="mail" element={<MailBoard />} />
            <Route path="meta" element={<MetaMobile />} />
            <Route path="web-chat" element={<PluginMobile />} />
            <Route path="users" element={<Users />} />
            <Route path="plugin" element={<Plugin />} />
            <Route path="analysis" element={<Analysis />} />
            <Route path="profile" element={<Profile />} />
            {/* {roles?.includes(USER_ROLE.READ_TICKET) ? ( */}
            <>
              <Route path="tickets" element={<Tickets />} />
              <Route path="tickets/:id" element={<TicketDetail />} />
            </>
            {/* ) : (
              <></>
            )} */}

            <Route path="settings" element={<Settings />}>
              <Route index element={<SettingsProfile />} />
              <Route path="connections" element={<SettingsConnections />} />
              <Route path="knowledge" element={<SettingsKnowledgeBase />} />
              <Route path="template" element={<SettingsTemplate />} />
              <Route path="plugin" element={<SettingsPlugin />} />
              <Route path="team" element={<SettingsTeam />} />
            </Route>
            {/* {roles?.includes(USER_ROLE.WRITE_KNOWLEDGE) && ( */}
            <Route path="settings/webpage/:id" element={<WebpageDetail />} />
            {/* )} */}

            <Route path="subscription" element={<Subscription />} />
            {org?.isOwner && <Route path="team" element={<Team />} />}
            {isMobile ? (
              <Route path="brand-voice" element={<BrandVoiceMobile />} />
            ) : (
              <Route path="brand-voice" element={<BrandVoice />} />
            )}
            <Route path="mail/:mailId" element={<MailBoard />} />
            <Route path="settings/gmail/authorize/callback" element={<GmailCallBack />} />
            <Route path="settings/outlook/authorize/callback" element={<OutlookCallBack />} />
            <Route path="*" element={<Navigate to="inbox" />} />
          </Route>
          {isMobile ? <Route path="/" element={<LandingMobile />} /> : <Route path="/" element={<Landing />} />}

          <Route path="/login" element={<Login />} />
          <Route path="/policy" element={<Privacy />} />
          <Route path="/limited-use-disclosure" element={<GoogleLimitedPage />} />
          <Route path="/success" element={<LoginSuccess />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/livedemo" element={<LiveDemo />} />
        </Routes>
        <AuthGlobalEffect />
        <EmailGlobalEffect />
        <SettingsGlobalEffect />
        <InboxGlobalEffect />
        <UserGlobalEffect />
        <BrandVoiceGlobalEffect />
        <ShopConnectionsGlobalEffect />
        <SocketGlobalEffect />
      </Suspense>
      <ToastContainer />
    </div>
  );
}

export default hot(module)(App);
