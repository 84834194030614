/**
 * @description Component-Assignee
 */
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import './index.less';
import { ITicket } from '@/types/plugin';
import { useTeamMembers } from '@/features/team/hooks';
import { useOrg } from '@/features/settings/hooks';
import { Select } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { updateTicket } from '@/features/plugin/actions';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  ticket: ITicket;
}
const Assignee: FC<IPropTypes> = function ({ ticket }) {
  const org = useOrg();
  const members = useTeamMembers(org?.id);
  const membersFiltered = useMemo(
    () => members,
    // ?.filter((m) => m.status !== MEMBER_STATUS.PENDING)
    [members],
  );
  const dispatch = useAppDispatch();
  const [changing, setChanging] = useState(false);
  const changeAssignee = useCallback(
    async (assignees: string[]) => {
      try {
        setChanging(true);
        if (org) {
          await dispatch(
            updateTicket({
              ...ticket,
              assignees: assignees.join(','),
              orgId: org.id,
            }),
          );
        }
        setChanging(false);
      } catch (e) {
        setChanging(false);
      }
    },
    [dispatch, org, ticket],
  );
  return (
    <Select
      mode="multiple"
      onClick={(e) => e.stopPropagation()}
      loading={changing}
      onChange={changeAssignee}
      value={ticket.assignees}
      showArrow={true}
      placeholder="Assign to..."
      className="cor-assignee">
      {membersFiltered?.map((member) => (
        <Select.Option value={member.email} key={member.id}>
          {member.email}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(Assignee);
